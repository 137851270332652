import logo from './logo.jpg';
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";

import "./styles.css";

function App() {
  return (
    <div className="App">
	  	            <img
            src={logo}
            alt="cautabonapotrivita.ro"
            className="landing--img"
            style={{
              opacity: `0.8`,

            }}
          />
      <div className="container">

        <h1>
          Platforma cautabonapotrivita.ro
          <br />
          In curand
        </h1>
        <Timer />
        <Optin />
        <Preloader />
      </div>
    </div>
  );
}

export default App;
